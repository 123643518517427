import React, { useState } from 'react';
import Background from "./background/Background";
import QuestionTimer from "./counter/QuestionTimer";
import FirstPage from "./firstPage/FirstPage";
import File from "./file/File";
import Popup from "./components/Popup";
import BarPopup from "./components/BarPopup"; // BarPopup bileşenini import edin

function App() {
  const [showPopup, setShowPopup] = useState(true);
  const [showBarPopup, setShowBarPopup] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);

  const handleVote = (questionId) => {
    setCurrentQuestionId(questionId);
    setShowPopup(false);
    setShowBarPopup(true);
  };

  return (
    <div>
      <Background />
      <FirstPage />
      <File />
      <QuestionTimer/> 
      {showPopup && <Popup onVote={handleVote} />}
      {showBarPopup && <BarPopup questionId={currentQuestionId}/>}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import '../css/file.css';
import consoleIcon from '../img/terminal-3.png'; // Bu ikon Console için kullanılacak
import listIcon from '../img/popup.png'; // Bu ikon List için kullanılacak
import Console from '../console/Console';
import List from '../list/List';

const File = () => {
    const [showConsole, setShowConsole] = useState(false); // Console görünürlüğünü kontrol et
    const [showList, setShowList] = useState(false); // List görünürlüğünü kontrol et

    const handleConsoleIconClick = () => setShowConsole(!showConsole); // Console görünürlüğünü toggle et
    const handleListIconClick = () => {
        setShowList(!showList); // Mevcut değerin tersini ayarlar
    };
    return (
        <div className="icon-container">
            <img src={consoleIcon} alt="Open Console" onClick={handleConsoleIconClick} className="console-icon" />
            {showConsole && <Console />}


            {/* <img src={listIcon} alt="Open List" onClick={handleListIconClick} className="list-icon" />
            {showList && <List />}  */}

        </div>
    );
};

export default File;

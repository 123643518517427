import React, { useState, useEffect } from 'react';
import { getActiveQuestions } from '../services/questionService'; // Aktif soruları çeken fonksiyonu import et
import { updateUserResponse } from '../services/resultsService';
import '../css/popup.css';

const Popup = ({ onVote }) => {
  const [visible, setVisible] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      const activeQuestions = await getActiveQuestions(); // Aktif soruları çek
      if (activeQuestions && activeQuestions.length > 0) {
        setCurrentQuestion(activeQuestions[0]); // Aktif sorulardan ilkini seç
      } else {
        // Eğer aktif soru yoksa, popup'ı görünmez yap
        setVisible(false);
      }
    };

    fetchQuestions();
  }, []);

  const handleUserResponse = async (response) => {
    if (currentQuestion) {
      await updateUserResponse(currentQuestion.id, response);
      setVisible(false); // Popup'ı kapat
      onVote(currentQuestion.id); // Üst bileşene oylama yapıldığını bildir
      // Burada BarPopup göstermek istiyorsanız, bu kısmı uygulamanızın diğer kısımlarında yönetmelisiniz
    }
  };

  if (!visible || !currentQuestion) return null;
  return (
    <div className="popup">
      <div className="popup-inner">
        <p>{currentQuestion.description}</p>
        <div className="options">
          <button className="option-btn" style={{ backgroundColor: "#408558" }} onClick={() => handleUserResponse('Evet')}>Evet</button>
          <button className="option-btn" style={{ backgroundColor: "#CB444A" }} onClick={() => handleUserResponse('Hayır')}>Hayır</button>
        </div>
      </div>
    </div>
  );
};


export default Popup;

import { db } from '../config/firebaseConfig';
import { collection, getDocs, doc, getDoc, updateDoc, increment, setDoc } from 'firebase/firestore'; // Eksik olanları import ettik

const resultsCollectionRef = collection(db, "results");

// Soru yanıtlarını güncellemek ve/veya yeni sonuç belgesi oluşturmak için fonksiyon
export const updateUserResponse = async (questionId, response, options = { firstOption: "Evet", secondOption: "Hayır" }) => {
  const resultDocRef = doc(db, "results", questionId.toString());
  const docSnap = await getDoc(resultDocRef);

  if (!docSnap.exists()) {
    // Doküman yoksa, yeni bir doküman oluştur
    console.log(`Oluşturuluyor: ${questionId}, Yanıt: ${response}`);
    const newData = {
      firstOption: options.firstOption,
      firstOptionRate: response === options.firstOption ? 1 : 0,
      secondOption: options.secondOption,
      secondOptionRate: response === options.secondOption ? 1 : 0,
      questionId: questionId, // Bu, veritabanınızın tasarımına bağlı olarak sayı veya string olabilir
    };
    await setDoc(resultDocRef, newData);
  } else {
    // Doküman varsa, yanıtları güncelle
    console.log(`Güncelleniyor: ${questionId}, Yanıt: ${response}`);
    const updateData = response === options.firstOption ? 
      { firstOptionRate: increment(1) } : 
      { secondOptionRate: increment(1) };
    await updateDoc(resultDocRef, updateData);
  }
};

// Belirli bir questionId için sonuçları çeken fonksiyon
export const getResultByQuestionId = async (questionId) => {
  const resultDocRef = doc(db, "results", questionId.toString());
  const docSnap = await getDoc(resultDocRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    console.log("No such document!");
    return null;
  }
};

// Tüm sonuçları getir
export const getResults = async () => {
  const resultsCollectionRef = collection(db, "results");
  const data = await getDocs(resultsCollectionRef);
  return data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

export const calculateResponsePercentages = async (questionId) => {
  const result = await getResultByQuestionId(questionId);
  if (result) {
    const { firstOptionRate, secondOptionRate } = result;
    const totalVotes = firstOptionRate + secondOptionRate;
    
    if (totalVotes === 0) {
      return { firstOptionPercentage: 0, secondOptionPercentage: 0 };
    }

    const firstOptionPercentage = (firstOptionRate / totalVotes) * 100;
    const secondOptionPercentage = (secondOptionRate / totalVotes) * 100;

    return {
      firstOptionPercentage: firstOptionPercentage.toFixed(2), // Ondalık kısmı iki basamakla sınırla
      secondOptionPercentage: secondOptionPercentage.toFixed(2), // Ondalık kısmı iki basamakla sınırla
    };
  } else {
    console.log("Belirtilen soru için sonuç belgesi bulunamadı.");
    return null;
  }
};
// Firebase v9+ için import yöntemleri
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

// Firebase projenizin yapılandırma bilgileri
const firebaseConfig = {
  apiKey: "AIzaSyCxVIhaA1CoxGFVQLTj-sTOageTsOVCLxE",
  authDomain: "kuzgun-birfikir.firebaseapp.com",
  projectId: "kuzgun-birfikir",
  storageBucket: "kuzgun-birfikir.appspot.com",
  messagingSenderId: "333660052573",
  appId: "1:333660052573:web:9409eee87de173f069c1b4"
};

// Firebase uygulamasını başlat (modular API kullanılarak)
const app = initializeApp(firebaseConfig);

// Firestore örneğini al (modular API kullanılarak)
const db = getFirestore(app);

// Firestore'dan belirli bir koleksiyonun tüm belgelerini çeken fonksiyon
export const getCollectionData = async (collectionName) => {
  const collectionRef = collection(db, collectionName);
  const querySnapshot = await getDocs(collectionRef);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export { db }; 
import React, {useMemo} from 'react';
import '../css/background.css';

const Background = () => {
    const { lineLength, lineCount } = useMemo(() => {
        const width = window.innerWidth;

        if (width < 768) { // Mobil cihazlar için
            return { lineLength: 40, lineCount: 30 };
        } else if (width >= 768 && width < 1024) { // Tablet cihazlar için
            return { lineLength: 70, lineCount: 40 };
        } else { // PC ve daha büyük ekranlar için
            return { lineLength: 125, lineCount: 80 };
        }
    }, []);

    const lines = useMemo(() => {
        const generateRandomChar = () => {
            const chars = ['1', '0']; 
            return chars[Math.floor(Math.random() * chars.length)];
        };

        return Array.from({ length: lineCount }).map(() =>
            Array.from({ length: lineLength }, () => generateRandomChar()).join('')
        );
    }, []); // Bağımlılıklar boş olduğundan, bu dizi sadece bir kez oluşturulur

    return (
        <div className="background-container">
            {lines.map((line, idx) => (
                <div key={idx} className="binary-line">
                    {line.split('').map((char, charIdx) => (
                        <span key={charIdx} className="binary-char" style={{ animationDelay: `${Math.random() * 2}s` }}>
                            {char}
                        </span>
                    ))}
                </div>
            ))}
        </div>
    );
};
export default Background;

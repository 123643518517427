import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown-now';
import '../css/questionTimer.css';

const formatTime = number => number.toString().padStart(2, '0');

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <span>Geri sayım tamamlandı!</span>;
  } else {
    return (
      <div className="countdown-display">
        <div className="time-section">{formatTime(days)}:</div>
        <div className="time-section">{formatTime(hours)}:</div>
        <div className="time-section">{formatTime(minutes)}:</div>
        <div className="time-section">{formatTime(seconds)}</div>
      </div>
    );
  }
};
const QuestionTimer = () => {
  const [isVisible, setIsVisible] = useState(false); // Komponentin görünürlüğünü kontrol etmek için

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true); // 4 saniye sonra komponenti görünür yap
    }, 3000); // 4000 milisaniye = 4 saniye

    return () => clearTimeout(timer); // Unmount edildiğinde zamanlayıcıyı temizle
  }, []);

  // Belirli bir tarihe ve saate kadar geri sayım
  // Örnek olarak 25 Aralık 2024, saat 15:00 kullanılmıştır
  const countdownDate = new Date("2024-03-15T21:00:00");

  // isVisible durumuna göre komponenti göster veya gizle
  return isVisible ? <Countdown date={countdownDate} renderer={renderer} /> : null;
};

export default QuestionTimer;

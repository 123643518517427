import React, { useState, useEffect } from 'react';
import kuzgun from '../img/kuzgun.png'; // Resmin yolu
import '../css/firstPage.css'; // CSS dosyası

const FirstPage = () => {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 2000); // 4 saniye sonra resmi gizle
  
    return () => clearTimeout(timer); // Unmount sırasında timer'ı temizle
  }, []);

  return (
    <div className="first-page">
      {showImage && <img src={kuzgun} alt="Açıklama" className="centered-image" />}
    </div>
  );
};

export default FirstPage;

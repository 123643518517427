import React, { useEffect, useState } from 'react';
import { db } from '../config/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import '../css/barPopup.css';
import x from '../img/x-button.png';

const BarPopup = ({ questionId }) => {
  const [percentages, setPercentages] = useState({ firstOptionPercentage: 0, secondOptionPercentage: 0 });
  const [visible, setVisible] = useState(true);
  const handleClose = () => setVisible(false);
  
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "results", questionId.toString()), (doc) => {
      const data = doc.data();
      if (data) {
        const totalVotes = data.firstOptionRate + data.secondOptionRate;
        const firstOptionPercentage = totalVotes > 0 ? (data.firstOptionRate / totalVotes) * 100 : 0;
        const secondOptionPercentage = totalVotes > 0 ? (data.secondOptionRate / totalVotes) * 100 : 0;
        setPercentages({
          firstOptionPercentage: firstOptionPercentage.toFixed(2),
          secondOptionPercentage: secondOptionPercentage.toFixed(2),
        });
      }
    });

    return () => unsubscribe();
  }, [questionId]);

  return visible ? (
    <div>
       <div className="bar-popup">
            <div className="bar-popup-inner">

            <p>Oylama Sonuçları</p>
            <button className="bar-close-btn" onClick={handleClose}><img src={x} alt="Close" /></button>
            <div className="progress" style={{ height: '40px', marginBottom: '20px' }}>
        <div className="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style={{ width: `${percentages.firstOptionPercentage}%` }} aria-valuenow={percentages.firstOptionPercentage} aria-valuemin="0" aria-valuemax="100">
          Evet: {percentages.firstOptionPercentage}%
        </div>
      </div>
      <div className="progress" style={{ height: '40px' }}>
        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" style={{ width: `${percentages.secondOptionPercentage}%` }} aria-valuenow={percentages.secondOptionPercentage} aria-valuemin="0" aria-valuemax="100">
          Hayır: {percentages.secondOptionPercentage}%
        </div>
      </div>
    </div>
  </div>
    </div>
  ):null ;
};

export default BarPopup;
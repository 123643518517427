import { db } from '../config/firebaseConfig'; // Firebase yapılandırma dosyanızın yolu
import { collection, getDocs, addDoc, updateDoc, doc, query, where, orderBy } from 'firebase/firestore';

// 'questions' koleksiyonunu referans al
const questionsCollectionRef = collection(db, "questions");

// Tüm soruları çek
export const getQuestions = async () => {
  const data = await getDocs(questionsCollectionRef);
  return data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};



// Yeni bir soru ekle
export const addQuestion = async (newQuestion) => {
  return await addDoc(questionsCollectionRef, newQuestion);
};

// Bir soruyu güncelle
export const updateQuestion = async (id, updatedQuestion) => {
  const questionDoc = doc(db, "questions", id);
  return await updateDoc(questionDoc, updatedQuestion);
};
export const getActiveQuestions = async () => {
  const now = new Date();
  const questionsRef = collection(db, "questions");
  const q = query(
    questionsRef, 
    where("endDate", ">=", now), // Yalnızca startDate'e göre filtreleme
    orderBy("endDate")
  );

  const querySnapshot = await getDocs(q);
  let questions = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const endDate = data.endDate.toDate(); // Firestore Timestamp'ını Date'e çevir
    if (endDate >= now) { // endDate şu anki tarih/saatten sonra ise
      questions.push({ id: doc.id, ...data });
    }
  });

  return questions;
};

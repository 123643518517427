import React, { useEffect, useState } from 'react';
import { Terminal } from 'primereact/terminal';
import { TerminalService } from 'primereact/terminalservice';
import '../css/console.css';
import x from '../img/x-button.png';

const Console = () => {
    const [currentNode, setCurrentNode] = useState('start');
    const [isTerminalActive, setIsTerminalActive] = useState(true);
    const [visible, setVisible] = useState(true);
    const [message, setMessage] = useState("");

    const handleClose = () => setVisible(false);

    function normalizeInput(input) {
        const lowerCaseInput = input.toLowerCase();
        return lowerCaseInput
            .replace(/ğ/g, 'g')
            .replace(/ü/g, 'u')
            .replace(/ş/g, 's')
            .replace(/ı/g, 'i')
            .replace(/ö/g, 'o')
            .replace(/ç/g, 'c')
            .replace(/Ğ/g, 'g')
            .replace(/Ü/g, 'u')
            .replace(/Ş/g, 's')
            .replace(/İ/g, 'i')
            .replace(/Ö/g, 'o')
            .replace(/Ç/g, 'c');
    }

    const flow = {
        start: {
            message: 'Mumu yakacak mısın?',
            evet: 'query',
            hayir: 'endConversation',
            default: 'endConversation',
        },
        query: {
            message: 'Bilmemi İstediğin bir şey var mı?',
            evet: 'leaveMessage',
            hayir: 'endConversation2',
            default: 'endConversation2',
        },
        leaveMessage: {
            message: 'Lütfen mesaj bırak. Kimse kimliğini bilmeyecek.',
            default: 'endConversation3',
        },
        endConversation: {
            message: 'Uzun süre karanlıkta kalırsan gözlerin alışır. Bir mum tek başına bile karanlıkla savaşır. Kazanacağım.',
        },
        endConversation2: {
            message: 'Yaktığın mum yolunu aydınlatsın.',
        },
        endConversation3: {
            message: 'Yakında bir mesaj alacaksın. O zamana dek mumun yolunu aydınlatsın.',
        },
    };
    
    const commandListener = (command) => {
        if (!isTerminalActive) return;
    
        const normalizedCommand = normalizeInput(command);
        const node = flow[currentNode]; // Mevcut durumu al
        let nextNodeKey = node[normalizedCommand] || node.default; // Komut için bir sonraki durumu veya varsayılanı al
    
        const nextNode = flow[nextNodeKey];
        if (nextNode) {
            setCurrentNode(nextNodeKey); // Sonraki durumu ayarla
            TerminalService.emit('response', nextNode.message); // Sonraki durumun mesajını göster
            console.log(message, nextNode, nextNodeKey, currentNode );
    
            if (nextNodeKey === 'endConversation' || nextNodeKey === 'endConversation2' || nextNodeKey === 'endConversation3') {
                setMessage(nextNode.message); // Mesajı güncelle
                setIsTerminalActive(false); // Terminali pasif hale getir
            }
        }
    };

    useEffect(() => {

  console.log(`Current node is now: ${currentNode}`);
        TerminalService.on('command', commandListener);

        return () => {
            TerminalService.off('command', commandListener);
        };
    }, [currentNode]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' || event.key === "KEY_ENTER" 
            || event.key === 'Go' || event.key === 'Send'
            || event.key === 'GO' || event.key === 'ENTER'
            || event.key === 'Send' || event.key === 'SEND'
            || event.key === 'Git' ) {
                console.log('Mobil cihazda Enter tuşuna basıldı.');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return visible ? (
        <div className="console-wrapper">
            <div className="console">
                <header>
                    <p>kuzgun@birfikir</p>
                    <button className="console-close-btn" onClick={handleClose}>
                        <img src={x} alt="Close" />
                    </button>
                </header>
                <div className="consolebody">
                    {isTerminalActive ? (
                        <Terminal
                            prompt="kuzgun@birfikir:~$"
                            welcomeMessage={flow.start.message}
                            commandHandler={commandListener}
                        />
                    ) : (
                        <div className="terminal-disabled">
                            <p>{message}</p> {/* Pasif durumdayken mesajı burada göster */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null;
};

export default Console;
